﻿(function (document) {
  'use strict';

  var tableFilter = (function () {
    function _onInputEvent(e) {
      var input = e.currentTarget;
      var tables = document.getElementsByClassName(input.getAttribute('data-table'));

      for (var i = 0; i < tables.length; i++) {
        var tableBodies = tables[i].tBodies;
        for (var j = 0; j < tableBodies.length; j++) {
          var rows = tableBodies[j].rows;
          for (var k = 0; k < rows.length; k++) {
            _filter(rows[k], input);
          }
        }
      }
    }

    function _filter(row, input) {
      var text = row.textContent.toLowerCase();
      var value = input.value.toLowerCase();
      row.style.display = text.indexOf(value) === -1 ? 'none' : 'table-row';
    }

    return {
      init: function () {

        var inputs = document.getElementsByClassName('filter-table-input');

        for (var i = 0; i < inputs.length; i++) {
          inputs[i].addEventListener('search', _onInputEvent);
          inputs[i].addEventListener('keyup', _onInputEvent);
          inputs[i].addEventListener('change', _onInputEvent);
        }
      }
    };
  })();

  document.addEventListener('readystatechange', function () {
    if (document.readyState === 'complete') {
      tableFilter.init();
    }
  });
})(document);