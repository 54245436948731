import jquery from "../../node_modules/jquery/dist/jquery.js";
window.jQuery = jquery;
window.$ = jquery;

import bootstrap from "../../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import "../../node_modules/@hobbylobby/ui-toolkit-bootstrap/dist/js/hl-ui-toolkit.js"
window.bootstrap = bootstrap;

import tomSelect from "../../node_modules/tom-select/dist/js/tom-select.complete.js";
window.TomSelect = tomSelect;

// import "../../node_modules/datatables.net/js/jquery.dataTables.js";

import DataTable from "../../node_modules/datatables.net-bs5/js/dataTables.bootstrap5.js";
window.DataTable = DataTable;
$.DataTable = DataTable;

import "./careers/careers-datatables-plugins.js";

import "./careers/careers-filter-table.js";
import "./careers/careers-sort-table.js";

